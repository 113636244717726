import React from 'react';
import "../styles/Portfolio.css";
import laptopdbImage from "../assets/laptopdb-min.webp";
import jobaidImage from "../assets/jobaid-min.webp";
import soleReliefImage from "../assets/solerelief-min.webp";
import pdfflowImage from "../assets/pdfflow-min.webp";
import intrinsicImage from "../assets/Intrinsic-min.webp";
import scormImage from "../assets/SCORM-min.webp";

const card_data = [
  {
    id: 1,
    image: scormImage,
    title: "SCORM Virtual Tour",
    description:
      "An MVP of a virtual guided tour created using React Three Fiber with points of interest, quizzes to test knowledge, and a final exam for retention. SCORM compliance ensures proper progress tracking through an LMS.",
    link: { Open: "https://brandongorman.me/ole/index.html" },
    technologies: [
      "SCORM API",
      "React",
      "Ant Design",
      "React Three Fiber",
      "GitHub Actions",
    ],
  },
  {
    id: 2,
    image: soleReliefImage,
    title: "Sole Relief",
    description:
      "Developed a WordPress website for SoleRelief, a local mobile foot care business. Managed all aspects of the project from the domain to deployment, including DNS setup, hosting, and logo recreation.",
    link: { Website: "https://www.solereliefns.ca" },
    technologies: [
      "WordPress",
      "cPanel",
      "DNS Management",
      "GoBlueBit Hosting",
      "Inkscape",
    ],
  },
  {
    id: 3,
    image: intrinsicImage,
    title: "Intrinsic",
    description:
      "Intrinsic evaluates a stock's intrinsic value based on various metrics and displays an overall 'Quality' score. Users can search for stocks and view their financials.",
    link: { Github: "https://github.com/bgorman87/Intrinsic", Open: "https://intrinsic.brandongorman.me/" },
    technologies: [
      "TypeScript",
      "React",
      "Express",
      "Docker",
      "Postgres",
      "GitHub",
      "Nginx",
      "Apache",
    ],
  },
  {
    id: 4,
    image: laptopdbImage,
    title: "Laptop Database",
    description:
      "Laptop-DB is a web app for managing laptops and parts. Users can search for laptop info by model/serial number and find associated parts.",
    link: { Github: "https://github.com/bgorman87/laptop-db", Open: "https://laptop-db.com/" },
    technologies: [
      "Django",
      "Bootstrap",
      "S3",
      "GitHub",
      "CodePipeline",
      "cPanel",
      "SQLite3",
    ],
  },
  {
    id: 5,
    image: pdfflowImage,
    title: "PDF Flow",
    description:
      "Multithreaded PDF processing tool that uses OCR to read normal or scanned PDF reports, rename them based on templates and content, sort them into folders, and email them to a contact list.",
    link: { Github: "https://github.com/bgorman87/PDF-Flow", Open: "https://pdfflow.godevservices.com/" },
    technologies: [
      "Python",
      "PySide6",
      "OCR",
      "MVVM Design",
      "GitHub",
      "SQLite3",
      "cx_Freeze",
      "AWS Services",
      "MySQL",
    ],
  },
  {
    id: 6,
    image: jobaidImage,
    title: "Progressive Web Application",
    description:
      "Created a PWA to host job aid PDFs for various departments. Chosen for desktop and mobile compatibility, along with standalone app functionality.",
    link: "",
    technologies: [
      "React",
      "S3",
      "CloudFront",
      "Lambda@Edge",
      "Service Worker",
      "JWT",
      "IndexedDB",
    ],
  },
];

const Cards = card_data.map((card) => (
  <article key={card.id} className="card">
    <figure className="image">
      <img src={card.image} alt={card.title} />
    </figure>
    <div className="title-container">
      <h2 className="title">{card.title}</h2>
      {card.link && (
        <nav className="links" aria-label={`Links for ${card.title}`}>
          <ul>
            {Object.entries(card.link).map(([linkTitle, linkUrl]) => (
              <li key={linkTitle}>
                <a
                  href={linkUrl}
                  className="link-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  {linkTitle}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
    <hr />
    <p className="description">{card.description}</p>
    <h3 className="technologies-title">Technologies:</h3>
    <div className="technologies">
      <ul className="col">
        {card.technologies
          .slice(0, Math.ceil(card.technologies.length / 2))
          .map((tech) => (
            <li key={tech}>{tech}</li>
          ))}
      </ul>
      <ul className="col">
        {card.technologies
          .slice(Math.ceil(card.technologies.length / 2))
          .map((tech) => (
            <li key={tech}>{tech}</li>
          ))}
      </ul>
    </div>
  </article>
));

const Portfolio = () => {
  return (
    <section id="portfolio" className="full-page portfolio" aria-label="Portfolio">
      <div className="card-container">{Cards}</div>
    </section>
  );
};

export default Portfolio;
